export const PAGES = [5, 10, 15, 25, 50, 100, 250, 500];
export const PER_PAGE = 10;
export const TOKEN = "token";
export const HSM = "hsm";
export const dataKeys = `Các trường dữ liệu: 
$${"{MauSo}"} Mẫu số
$${"{KyHieu}"} Ký hiệu
$${"{SoHoaDon}"} Số hóa đơn
$${"{SoHoaDonGoc}"} Số hóa đơn gốc
$${"{NgayLap}"} Ngày lập hóa đơn        
$${"{MstDNBan}"} Mã số thuế doanh nghiệp bán
$${"{TenDNBan}"} Tên doanh nghiệp bán
$${"{MstDNMua}"} Mã số thuế doanh nghiệp mua
$${"{TenDNMua}"} Tên doanh nghiệp mua
$${"{LinkHoaDon}"} Địa chỉ tra cứu hóa đơn
$${"{LinkThongBao}"} Địa chỉ tra cứu thông báo phát hành
$${"{LinkQuyetDinh}"} Địa chỉ tra cứu quyết định phát hành
$${"{TaiKhoanTraCuu}"} Tài khoản đăng nhập tra cứu
$${"{MatKhauTraCuu}"} Mật khẩu đăng nhập tra cứu
$${"{TenNguoiMua}"} Tên người mua`
export const invoiceStatus = ["Đã tạo hóa đơn","Cần nhập liệu","Hóa đơn trùng","Hóa đơn không hợp lệ"]
export const productStatus = ["Không hoạt động","Hoạt động","Đã xóa"]
export const userStatus = ["Không hoạt động","Đang hoạt động","Đã khóa"]
export const contractStatus = ["Chưa ký","Đã ký"]
export const packageStatus = ["Hoạt động","Kết thúc","Sử dụng hết","Chưa ký hợp đồng"]
export const emailTypeArr = [
    "@xcyber.vn",
    "Tài khoản gmail",
    "Khác (Nhập đầy đủ địa chỉ)"
]
export const typeInvoiceArr = [
    {
        label: "Hóa đơn gốc",
        value: 1
    },
    {
        label: "Hóa đơn thay thế",
        value: 2
    },
    {
        label: "Hóa đơn điều chỉnh",
        value: 3
    },
    {
        label: "Hóa đơn bị thay thế",
        value: 4
    },
    {
        label: "Hóa đơn bị điều chỉnh",
        value: 5
    },
    {
        label: "Hóa đơn bị xóa bỏ/hủy",
        value: 6
    },
]
export const statusInvoiceArr = [
    {
        label: "Chờ duyệt",
        value: 1
    },
    {
        label: "Đã duyệt",
        value: 2
    },
    {
        label: "Không duyệt",
        value: 3
    },
    {
        label: "Đã hủy",
        value: 4
    },
]
export const statusActiveSupplier = [
    {
        label: "Đã xóa",
        value: 0
    },
    {
        label: "Đang hoạt động",
        value: 1
    },
    {
        label: "Ngừng hoạt động",
        value: 2
    },
    {
        label: "Không tìm thấy thông tin tra cứu",
        value: 3
    },
    {
        label: "Chờ kiểm tra",
        value: 4
    },
    {
        label: "Chuyển cơ quan thuế",
        value: 5
    },
    {
        label: "Ngừng hoạt động chưa hoàn thành thủ tục",
        value: 6
    },
    {
        label: "Tạm ngừng hoạt động",
        value: 7
    },
    {
        label: "Không hoạt động tại địa chỉ đăng ký",
        value: 8
    },
    {
        label: "Chờ làm thủ tục phá sản",
        value: 9
    },
]

export const statusSupplierFromTct = [
    {
        label: "Đang hoạt động",
        value: 0
    },
    {
        label: "Ngừng hoạt động",
        value: 1
    },
    {
        label: "Chuyển cơ quan thuế",
        value: 2
    },
    {
        label: "Ngừng hoạt động chưa hoàn thành thủ tục",
        value: 3
    },
    {
        label: "Đang hoạt động",
        value: 4
    },
    {
        label: "Tạm ngừng hoạt động",
        value: 5
    },
    {
        label: "Không hoạt động tại địa chỉ đăng ký",
        value: 6
    },
    {
        label: "Chờ làm thủ tục phá sản",
        value: 7
    },
    {
        label: "Không tìm thấy thông tin tra cứu",
        value: 8
    },
    {
        label: "Chờ kiểm tra",
        value: 9
    }
]

export const defaultValueColumns = {
    "soHoaDon": {
        "show": true,
        "fixed": "left"
    },
    "ngayHoaDon": {
        "show": true
    },
    "loaiHoaDon": {
        "show": true
    },
    "tenNCC": {
        "show": true
    },
    "tongTien": {
        "show": true
    },
    "trangThaiPheDuyet": {
        "show": true
    },
    "ghiChu": {
        "show": true
    },
    "kiHieuMauSoHoaDon": {
        "show": false
    },
    "kiHieuHoaDon": {
        "show": false
    },
    "soDonHang": {
        "show": false
    },
    "tinhChatHoaDon": {
        "show": false
    },
    "tenDoanhNghiep": {
        "show": false
    },
    "ngayNhanHd": {
        "show": false
    },
    "option": {
        "show": true,
        "fixed": "right"
    }
}

export const optionsTime = [
    { value: 1, label: "Ngày hiện tại" },
    { value: 3, label: "Tháng hiện tại" },
    { value: 4, label: "Quý hiện tại" },
    { value: 5, label: "Năm hiện tại" },
];

export const optionsTimeSecond = [
    { value: 2, label: "Tuần hiện tại" },
    { value: 3, label: "Tháng hiện tại" },
    { value: 5, label: "Năm hiện tại" },
];

export const TCTInvoiceStatus = [
    {
        label: "Hóa đơn gốc",
        value: 1
    },
    {
        label: "Hóa đơn thay thế",
        value: 2
    },
    {
        label: "Hóa đơn điều chỉnh",
        value: 3
    },
    {
        label: "Hóa đơn đã bị thay thế",
        value: 4
    },
    {
        label: "Hóa đơn đã bị điều chỉnh",
        value: 5
    },
    {
        label: "Hóa đơn đã bị xóa bỏ/hủy bỏ",
        value: 6
    },
]

export const TCTInvoiceStatusProcess = [
    {
        label: "Tổng cục Thuế đã nhận",
        value: 0
    },
    {
        label: "Đang tiến hành kiểm tra điều kiện cấp mã",
        value: 1
    },
    {
        label: "CQT từ chối hóa đơn theo từng lần phát sinh",
        value: 2
    },
    {
        label: "Hóa đơn đủ điều kiện cấp mã",
        value: 3
    },
    {
        label: "Hóa đơn không đủ điều kiện cấp mã",
        value: 4
    },
    {
        label: "Đã cấp mã hóa đơn",
        value: 5
    },
    {
        label: "Tổng cục thuế đã nhận không mã",
        value: 6
    },
    {
        label: "Đã kiểm tra định kỳ HĐĐT không có mã",
        value: 7
    },
    {
        label: "Tổng cục thuế đã nhận không mã máy tính tiền",
        value: 8
    },
    {
        label: "Đã kiểm tra định kỳ HĐĐT không có mã máy tính tiền",
        value: 9
    },
]
export const colorVar = {
purple: "#7367f0", //$primary
green: "#23ad61", //$success
blue: "#009cad", //$info
orange: "#ff9f43", //$warning
red: "#ea5455", //$danger
primary: "#9f1d21",
secondary: "#82868b",
info: "#009cad",
warning: "#ff9f43",
light: "#f6f6f6 ",
dark: "#413b55",
darkTable: "#1d1d1d",
}
export const decimalPointArr = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
]

export const TCTInvoiceSigningStatus = [
    {
        label: "Chữ ký hợp lệ",
        value: 0
    },
    {
        label: "Không tìm thấy chữ ký số",
        value: 1
    },
    {
        label: "Dữ liệu đã bị thay đổi sau khi ký",
        value: 2
    },
    {
        label: "Chữ ký không hợp lệ",
        value: 3
    },
    {
        label: "Không ký đúng vị trí",
        value: 4
    },
    {
        label: "Không hợp lệ",
        value: 5
    },
    {
        label: "Không hợp lệ",
        value: 6
    },
]

export const historyConnectTCTStatus = [
    {
        label: "Đang đồng bộ",
        value: "DANG_DONG_BO"
    },
    {
        label: "Đồng bộ thành công",
        value: "THANH_CONG"
    },
    {
        label: "Đồng bộ thất bại",
        value: "THAT_BAI"
    },
]

export const historyInvoiceConnectTCTStatus = [
    {
        label: "Tải thành công",
        value: 6
    },
    {
        label: "Tải thất bại",
        value: 3
    },
]

